/** @jsx jsx */
import * as React from "react";
import { jsx } from "@emotion/react";
import { IData } from "./data-types";
import { StyledAnchor } from "../components/layoutElements";
import { Text, TextVariant } from "../components/typography";

const privacyData: IData = {
  title: "Our Privacy Policy",
  hero: {
    image_src: "",
    text_title: "Our Privacy Policy",
    text_body:
      "When you use our services, you’re trusting us with your information. We understand this is a big responsibility and work hard to protect your information and put you in control.",
  },
  contents: {
    text_title: "Contents",
    contentSections: [
      {
        name: "introduction",
        location: "introduction",
        text_body:
          "This privacy policy explains how we protect personal information (“personal data”) received, used and disclosed by users of the Bridge Platform. Personal information or personal data” means any direct information about you such as your name and contact information or indirect information that could be reasonably linked to you.",
      },
      {
        name: "your rights and choices",
        location: "your-rights-and-choices",
        text_body:
          "You may access, update, correct or inactivate your account at will. However, to comply with our obligations, your usage data and activity data are parts of our permanent records. ",
      },
      {
        name: "types of information we collect",
        location: "types-of-information-we-collect",
        subSections: [
          {
            text_title: "Who You Are (User Information)",
            text_body:
              "Information you provide to us for account registration, authentication, and the communications you send us.",
          },
          {
            text_title: "Usage Data (Where You Go)",
            text_body:
              "Technical information on what features of Bridge you use.",
          },
          {
            text_title: "Activity Data (What You Do)",
            text_body: "Information on your activities in Bridge.",
          },
        ],
      },
      {
        name: "why we collect this information",
        location: "why-we-collect-this-information",
        subSections: [
          {
            text_body:
              "We collect this information to better serve you, communicate with you, comply with state and federal laws, and prevent and handle cases of abuse or fraud. We do not use your information for advertising. We do not sell or lease your information.",
            text_title: "",
          },
          {
            text_title: "Maintain and improve our services",
            text_body:
              "We also use your information to ensure our services are working as intended, such as tracking outages or troubleshooting issues that you report to us. And we use your information to make improvements to our services — for example, understanding which search terms are most frequently misspelled helps us improve spell-check features used across our services.",
          },
          {
            text_title: "Develop new services",
            text_body:
              "We use the information we collect in existing services to help us develop new ones.",
          },
          {
            text_title: "measure performance",
            text_body:
              "We use data for analytics and measurement to understand how Peers Care is being used.",
          },
          {
            text_title: "Communicate with you",
            text_body:
              "We use information we collect, like your email address, to interact with you directly. For example, we may send you a notification if we detect suspicious activity, like an attempt to sign in to your Peers Care account from an unusual location. Or we may let you know about upcoming changes or improvements to our services. ",
          },
          {
            text_title: "Protect Peers Care, our users, and the public",
            text_body: [
              <>
                We use information to help improve the{" "}
                <StyledAnchor
                  href="https://policies.google.com/privacy?hl=en-US#footnote-safety-reliability"
                  variant={TextVariant.small_C}
                >
                  safety and reliability
                </StyledAnchor>{" "}
                of our services. We’ll ask for your consent before using your
                information for a purpose that isn’t covered in this Privacy
                Policy.
              </>,
            ],
          },
        ],
      },
      {
        name: "how we disclose information",
        location: "how-we-disclose-information",
        subSections: [
          {
            text_title: "with your consent",
            text_body:
              "We’ll ask for your explicit consent to share any sensitive personal information.",
          },
          {
            text_title: "for legal reasons",
            text_body: [
              "We will share personal information outside of Peers Care if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to:",
              <ul>
                <li>
                  Meet any applicable law, regulation, legal process, or
                  enforceable governmental request. We share information about
                  the number and type of requests we receive from governments in
                  our Transparency Report.
                </li>
                <li>
                  Enforce applicable Terms of Service, including investigation
                  of potential violations.
                </li>
                <li>
                  Detect, prevent, or otherwise address fraud, security, or
                  technical issues.
                </li>
                <li>
                  Protect against harm to the rights, property or safety of
                  Peers Care, our users, or the public as required or permitted
                  by law.
                </li>
              </ul>,
              "We may share non-personally identifiable information publicly and with our partners — like publishers, advertisers, developers, or rights holders. For example, we share information publicly to show trends about the general use of our services. We also allow specific partners to collect information from your browser or device for advertising and measurement purposes using their own cookies or similar technologies.",
            ],
          },
        ],
      },
      {
        name: "about this policy",
        location: "about-this-policy",
        text_title: "about this policy",
        text_body: [
          "This Privacy Policy applies to all of the services offered by Peers Care LLC and services offered on third-party sites, such as advertising services. This Privacy Policy doesn’t apply to services that have separate privacy policies that do not incorporate this Privacy Policy.",
          "This Privacy Policy doesn’t apply to:",
          <ul>
            <li>
              The information practices of other companies and organizations
              that advertise our services
            </li>
          </ul>,
        ],
      },
      {
        name: "updates",
        location: "updates",
        text_body:
          "We change this Privacy Policy from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. We always indicate the date the last changes were published and we offer access to archived versions for your review. If changes are significant, we’ll provide a more prominent notice (including, for certain services, email notification of Privacy Policy changes).",
      },
      {
        name: "Download the PDF",
        location: "download-the-pdf",
        text_body: [
          <Text variant={TextVariant.small_C}>
            <>
              Effective{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                ADD DATE HERE
              </span>{" "}
              <StyledAnchor
                variant={TextVariant.small_C}
                href={"/privacy.pdf"}
                download
              >
                Download PDF
              </StyledAnchor>
            </>
          </Text>,
        ],
      },
      {
        name: "contact us",
        location: "contact-us",
        text_body:
          "If you have any questions, comments or requests regarding this privacy policy or our processing of your information, please contact: privacypolicy@[email extension].com",
      },
    ],
  },
};

export { privacyData };
