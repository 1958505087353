/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useTheme } from "../components/ThemeProvider";
import * as React from "react";
import { Helmet } from "react-helmet";
import { privacyData as data } from "../data/privacy-policy-data";
import {
  Hero,
  Contents,
  InfoSections as TOCSections,
} from "../components/layoutElements";
import { IPageSection } from "../data/data-types";

function Privacy() {
  const theme = useTheme();
  const heroData = data.hero as IPageSection;
  const contentData = data.contents as IPageSection;
  const tocData = data.contents as IPageSection;

  return (
    <>
      <Helmet>
        <title>{data.title}</title>
      </Helmet>
      <main
        css={css({
          backgroundColor: theme.colors.alabaster,
        })}
      >
        <Hero data={heroData} smCol={12} />
        <Contents data={contentData} />
        <TOCSections data={tocData.contentSections} />
      </main>
    </>
  );
}

export default Privacy;
